import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { animate } from 'motion';
import { filter, map, Subscription, timer } from 'rxjs';

import { NavigationEnd, Router } from '@angular/router';
import { AppService } from '@logic-suite/shared';
import { TelemetryService } from '@logic-suite/shared/telemetry';
import { ThemeService } from '@logic-suite/shared/theme';
import { AppRoute, getRoutes } from './app.routing';
import { CustomerSelectionService } from './views/customer/customer-selection.service';
import { Customer } from './views/customer/customer.model';

@Component({
    selector: 'fla-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  routes: AppRoute[] = [];

  selectedCustomer?: Customer;

  @ViewChildren('item') linkItems!: QueryList<ElementRef<HTMLLIElement>>;

  get returnPath() {
    return location.pathname;
  }

  constructor(
    private theme: ThemeService,
    updates: SwUpdate,
    private app: AppService,
    private telemetry: TelemetryService,
    private router: Router,
    private customer: CustomerSelectionService,
  ) {
    // theme.listen();

    // Make sure we run on an updated version
    if (updates.isEnabled) {
      // Ask user for permission to update once a new update is registered
      this.subscriptions.push(
        updates.versionUpdates
          .pipe(
            filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
            map((evt) => ({
              type: 'UPDATE_AVAILABLE',
              current: evt.currentVersion,
              available: evt.latestVersion,
            })),
          )
          .subscribe(() => {
            if (confirm('A newer version of the application is available. Load the new version?')) {
              updates.activateUpdate().then(() => document.location.reload());
            }
          }),
      );

      // Check every 5 minutes for updates
      this.subscriptions.push(timer(0, 5 * 60 * 1000).subscribe((n) => updates.checkForUpdate()));
    }

    this.routes = getRoutes();
    const sub = this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((e) => {
      setTimeout(() => {
        const current = document.querySelector('li.current');
        if (current) {
          current.classList.add('active');
          sub.unsubscribe();
        }
      });
    });
  }

  ngOnInit() {
    // Start telemetry
    this.telemetry.init();

    this.app.setApplicationID(11);
    this.subscriptions.push(
      this.router.events
        .pipe(
          filter((e) => e instanceof NavigationEnd),
          map((e) => e as NavigationEnd),
        )
        .subscribe((n: NavigationEnd) => {
          const elm = this.linkItems.find((item) =>
            n.urlAfterRedirects.includes(item.nativeElement.dataset?.item ?? ''),
          );
          this.animateSelection(elm?.nativeElement as HTMLLIElement);
        }),
    );
    this.subscriptions.push(
      this.customer.getCustomer().subscribe((customer) => {
        this.selectedCustomer = customer;
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  animateSelection(toItem?: HTMLLIElement) {
    const fromItem = (document.querySelector('.active') || document.querySelector('.current')) as HTMLLIElement;
    if (fromItem) {
      if (toItem) {
        const animElement = document.createElement('div');
        animElement.classList.add('list-item', 'active', 'animating');
        animElement.innerHTML = '<a></a>';
        animElement.style.left = fromItem.offsetLeft + 'px';
        animElement.style.top = fromItem.offsetTop + 'px';
        animElement.style.width = fromItem.offsetWidth + 'px';
        animElement.style.height = fromItem.offsetHeight + 'px';
        document.body.appendChild(animElement);
        const ctrl = animate(
          animElement,
          {
            left: toItem.offsetLeft + 'px',
            width: toItem.offsetWidth + 'px',
          },
          { duration: 0.2, easing: 'ease-in-out' },
        );
        ctrl.finished.then(() => {
          toItem.classList.add('active');
          const fade = animate(animElement, { opacity: 0 }, { duration: 0.05 });
          fade.finished.then(() => animElement.remove());
          fade.play();
        });
        ctrl.play();
      }
      fromItem.classList.remove('active');
    } else if (toItem) {
      toItem.classList.add('active');
    }
  }
}
